import React from "react";
import styled from "styled-components";

const CharacterTileContainer = styled.div<{ 
  state?: "FOUND" | "LOCATED" | "ELIMINATED" 
  }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding-bottom: 2px;
  border: 1px solid #CCC;
  border-radius: 2px;
  box-shadow: 0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;

  ${({state}) => state === "FOUND" && `
    background-color: #6aaa64;
    color: #ffffff;
  `}

  ${({state}) => state === "LOCATED" && `
    background-color: #c9b458;
    color: #ffffff;
  `}
`;

interface CharacterTileProps {
  character: Character,
  /**
   * Optional character state styling, will color tile
   */
  state?: "FOUND" | "LOCATED" | "ELIMINATED",
}

const CharacterTile: React.FC<CharacterTileProps> = (props) => {

  return (
    <CharacterTileContainer state={props.state}>
      {props.character}
    </CharacterTileContainer>
  )
}

export { CharacterTile}