import React from 'react';
import styled from 'styled-components';

import { CharacterTile } from '@/components/basic/CharacterTile';

import { SearchContext } from '@/contexts/searchContext';

import type { WordResult } from '@/models/WordResult';

const ResultWordContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: 2px;
`

interface ResultListWordProps {
  word: Word;
}

const ResultListWord: React.FC<ResultListWordProps> = ({ word }) => {

  const searchContext = React.useContext(SearchContext);

  function highlightMatchingCharacters(word: Word) {
    return word.split('').map((character, index) => {
      if (searchContext.found[index] === character) {
        return ( <CharacterTile character={character} state="FOUND" />)
      } else if (searchContext.located.includes(character)) {
        return ( <CharacterTile character={character} state="LOCATED" /> )
      } else if (searchContext.notLocated.includes(character)) {
        return ( <CharacterTile character={character} state="ELIMINATED" /> )
      } else {
        return ( <CharacterTile character={character} /> )
      }
    })
  }


  return (
    <ResultWordContainer>
      {highlightMatchingCharacters(word)}
    </ResultWordContainer>
  )
}

export { ResultListWord }