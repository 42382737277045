import React, { useState } from 'react';
import styled from 'styled-components';

import { SeparatedCharacterInput } from '@components/search/SeparatedCharacterInput';
import { SCInput } from '@components/search/SCInput';

import { Button } from '@styled/Button';

const SearchFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`


const SearchForm: React.FC = () => {
  let [
    locatedCharacters,
    setLocatedCharacters
  ] = useState(Array(5).fill('_'));

  
  let [
    unlocatedCharacters, 
    setUnlocatedCharacters
  ] = useState([]);

  let [
    eliminatedCharacters, 
    setEliminatedCharacters
  ] = useState([]);

  return (
    <SearchFormContainer
      action="/search"
    >
      <label>Located letters</label>
      <SCInput value={locatedCharacters} />

      <label aria-label="Found but unlocated letters" htmlFor="unlocated-characters">Found but unlocated letters</label>
      <SeparatedCharacterInput
        name="has"
        id="unlocated-characters"
        value={unlocatedCharacters}
        onChange={setUnlocatedCharacters}
      />

      <label aria-label="Eliminated letters" htmlFor="eliminated-characters">Eliminated letters</label>
      <SeparatedCharacterInput
        name="not"
        id="eliminated-characters"
        value={eliminatedCharacters}
        onChange={setEliminatedCharacters}
      />

      <div>
        <button className="button__primary" type="submit">Search</button>
      </div>

    </SearchFormContainer>
  )
}

export { SearchForm }