import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

// Contexts
import { SearchContext } from '@/contexts/searchContext';

// Components
import { ResultList } from '@components/results/ResultList';

// Utils
import { baseURL } from '@utils/url';
import { sortByAlphabet, sortByEliminations } from '@/utils/sort';

// Enums
import { ResultsOrder, ScoreDisplay } from '@models/Search';

// Models
import type { SearchResponseBodyV2 } from '@models/Response';
import type { SearchFilters } from '@models/Search';
import type { WordResult } from '@/models/WordResult';

const Search: React.FC = () => {
  // State
  const [orderBy, setOrderBy] = React.useState<ResultsOrder>(ResultsOrder.ALPHABET);
  const [scoreDisplay, setScoreDisplay] = React.useState<ScoreDisplay>(ScoreDisplay.COUNT);

  // SearchContext
  const searchFilters: SearchFilters = {
    found: "_____",
    located: [],
    notLocated: [],
    resultsOrder: ResultsOrder.ALPHABET,
    scoreDisplay: scoreDisplay
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resourceURL = baseURL
  resourceURL.pathname = '/search'

  queryParams.forEach((value, key) => {
    resourceURL.searchParams.set(key, value);

    if (key === 'found') {
      searchFilters.found = value;
    } else if (key === 'has') {
      searchFilters.located = value.split('');
    } else if (key === 'not') {
      searchFilters.notLocated = value.split('');
    }
  })


  const handleOrderByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newOrder = parseInt(event.target.value) as ResultsOrder;
    setOrderBy(newOrder);
  };

  const handleScoreDisplayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newScoreDisplay = parseInt(event.target.value) as ScoreDisplay;
    setScoreDisplay(newScoreDisplay);
  };


  const { status, error, data } = useQuery<SearchResponseBodyV2>({
    queryKey: ['resultsData'],
    queryFn: () =>
      fetch(resourceURL).then((res) => {
        return res.json()
      }),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })

  const remainingPossibleAnswers: (orderState: ResultsOrder) => WordResult[] = (orderState) => {
    if (orderState === ResultsOrder.ALPHABET) {
      return sortByAlphabet(data!.data.results.remainingPossibleAnswers)
    } else {
      return sortByEliminations(data!.data.results.remainingPossibleAnswers)
    }
  }

  return (
    <div>
      {status === "success" &&
        <SearchContext.Provider value={searchFilters}>
          <div>
            <select name="orderBy" value={orderBy} onChange={handleOrderByChange}>
              <option value={ResultsOrder.ALPHABET}>Alphabetical</option>
              <option value={ResultsOrder.ELIMINATIONS}>Eliminations</option>
            </select>
          </div>

          <div>
            <select name="scoreDisplay" value={scoreDisplay} onChange={handleScoreDisplayChange}>
              <option value={ScoreDisplay.COUNT}>Count</option>
              <option value={ScoreDisplay.PERCENTAGE}>Percentage</option>
            </select>
          </div>

          <h1>Remaining answers</h1>
          <ResultList results={remainingPossibleAnswers(orderBy)} />

          <h1>Eliminated answers</h1>
          <ResultList results={data.data.results.eliminatedPossibleAnswers.highPotential} />

          <h1>Invalids</h1>
          <ResultList results={data.data.results.nonanswers.highPotential} />
        </SearchContext.Provider>
      }

      {status === 'pending' &&
        <p>Loading...</p>
      }

      {status === 'error' &&
        <p>Something is wrong with the server, sorry. :(</p>
      }
    </div>
  )
}

export { Search }