/* eslint-disable @typescript-eslint/no-useless-constructor */
import React, { useState } from 'react';
import styled from 'styled-components';

import { isAlphabetic } from '@utils/alphabet';

const SeparatedCharacterInputContainer = styled.div`
  cursor: text;

  border-radius: var(--input-border-radius);
  background: var(--input-bg-color);

  min-width: 80px;
  height: 40px;
  min-height: 40px;
  padding: 4px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

  &:focus,
  &:focus-within {

  }
`

const InvisibleInput = styled.input`
  background: transparent;
  width: 4px;
  height: 100%;
  font-size: 100%;
  margin-left: 6px;

  &,
  &:focus,
  &:hover {
    outline: none;
    border: none;
  }
`

const SeparatedCharacterInputTile = styled.div`
  cursor: pointer;

  background: hsl(0, 0%, 92%);
  border-radius: var(--input-border-radius);

  min-width: 5px;
  height: 100%;
  aspect-ratio: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;
`

interface Props {
  id?: string;
  name: string;
  value: string[];
  onChange: React.Dispatch<any>;
}


class SeparatedCharacterInput extends React.Component<Props> {
  containerRef = React.createRef<HTMLInputElement>();
  inputRef = React.createRef<HTMLInputElement>();
  inputValue: string = '';
  state: {
    focused: boolean;
  }

  setValue: React.Dispatch<any>; // internal name for onChange prop

  constructor(props: Props)  {
      super(props)
      this.setValue = props.onChange

      this.state = {
        focused: false
      }
  }

  focusInput = () => {
    this.inputRef.current!.focus()
    this.setState({ focused: true })
  }

  blurInput = () => {
    this.setState({ focused: false })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.inputRef.current) return;

    const enteredCharacter = e.target.value

    // Check if alphabetic
    if (isAlphabetic(enteredCharacter)) {
      // Check if not redundant
      if (!this.props.value.includes(enteredCharacter)) {

        const newValues = [...this.props.value, enteredCharacter]
        
        this.setValue(newValues)
      }
    }

    this.inputRef.current.value = ''
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle deletion
    if (e.key === 'Backspace') {
      const newValues = [...this.props.value]
      newValues.pop()
      this.props.onChange(newValues)
    }
  }

  /**
   * When the user clicks on a character tile, delete it
   * @param index 
   */
  deleteCharacterTile = (index: number) => {
    const newValues = [...this.props.value]
    newValues.splice(index, 1)
    this.props.onChange(newValues)
  }

  render() {
    return (
      <SeparatedCharacterInputContainer 
        ref={this.containerRef}
        onFocus={this.focusInput}
        onBlur={this.blurInput}
        onClick={this.focusInput}
      >

        {/* { this.props.value.length === 0 && 
          !this.state.focused && (
          <SeparatedCharacterInputTile>
            +
          </SeparatedCharacterInputTile>
        )} */}

        {this.props.value.map((value, index) => (
          <SeparatedCharacterInputTile
            data-testid={this.props.id + '-tile'}
            key={index}
            onClick={() => this.deleteCharacterTile(index)} // Pass a function reference
          >
            { value }
          </SeparatedCharacterInputTile>
        ))}

        <InvisibleInput
          data-testid={this.props.id + '-input'}
          tabIndex={0} // Non-tabable
          ref={this.inputRef}
          onChange={this.handleChange}
          onKeyDownCapture={this.handleKeyDown}
          id={this.props.id}
          role={'textbox'}
          contentEditable="true"
          aria-placeholder=""
          aria-labelledby={this.props.id}
        />

        <input type="hidden" name={this.props.name} value={this.props.value.join('')}></input>
      </SeparatedCharacterInputContainer>
    )
  }
}

export { SeparatedCharacterInput }