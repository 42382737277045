import React from "react"

import { ResultsOrder, ScoreDisplay } from "@models/Search"
import type { SearchFilters } from "@models/Search";

const params = {
  found: "_____",
  located: [],
  notLocated: [],
  resultsOrder: ResultsOrder.ALPHABET,
  scoreDisplay: ScoreDisplay.COUNT
}

export const SearchContext = React.createContext<SearchFilters>(params)