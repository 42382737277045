export interface SearchFilters {
  found: String,
  located: Character[],
  notLocated: Character[],
  resultsOrder: ResultsOrder,
  scoreDisplay: ScoreDisplay,
}

export enum ResultsOrder {
  ALPHABET,
  ELIMINATIONS
} 

export enum ScoreDisplay {
  COUNT,
  PERCENTAGE
}