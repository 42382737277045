import React from 'react';
import styled from 'styled-components';

import { ResultListItem } from '@/components/results/ResultListItem';

import type { WordResult } from '@/models/WordResult';

const ResultListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`

interface ResultListProps {
  results: WordResult[],
  page?: number
}

const ResultList: React.FC<ResultListProps> = (props) => {
  return (
    <ResultListContainer>
      {props.results.map((result, index) => (
        <ResultListItem key={result.word} result={result} />
      ))}
    </ResultListContainer>
  )
}

export {
  ResultList
}