import React from 'react';

import {
  RouterProvider,
} from "react-router-dom";

import router from './router/router';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import logo from './logo.svg';
import './App.css';

import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'


// Style stuff
const Style = createGlobalStyle`
  body { 
    padding: 0;
    margin: 0;
  }
`

interface Theme {
  [key: string]: any;
  colors: ColorTheme;
}

interface ColorTheme {
  green: string;
  yellow: string;
}

// Subthemes that get composed
const lightSubtheme: ColorTheme = {
  green: '#6aaa64',
  yellow: '#c9b458',
}

const darkSubtheme: ColorTheme = {
  green: '#538d4e',
  yellow: '#b59f3b',
}

// FInal composed themes
const lightTheme: Theme = {
  colors: lightSubtheme,
}

const darkTheme: Theme = {
  colors: darkSubtheme,
}


// React Query
const queryClient = new QueryClient()


function App() {
  let darkColorScheme = false

  if (window.matchMedia) {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      const darkColorScheme = event.matches ? true : false;
    });
  }

  return (
    <ThemeProvider theme={darkColorScheme ? darkTheme : lightTheme}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Style></Style>
          <RouterProvider router={router} />
        </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
