import React from 'react'
import styled from 'styled-components';

import { isAlphabetic } from '@utils/alphabet';


const SCInputGroup = styled.div`
  /* background: ; */
  border-radius: var(--input-border-radius);
  
  width: 500px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 8px;
`

const SCInputElem = styled.input<{
  $filled?: boolean;
}>`
  background: ${props => props.$filled ? props.theme.colors.green : 'var(--input-bg-color)'};
  border: none;
  border-radius: var(--input-border-radius);

  width: 20%;
  aspect-ratio: 1/1;
  font-size: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  text-transform: uppercase;
`

interface SCInputProps {
  value?: Character[];
}

const SCInput: React.FC<SCInputProps> = ({
  value = Array(5).fill(''),
}: SCInputProps) => {
  let [groupValue, setGroupValue] = React.useState("_____");

  const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);

  // Runs after mounting
  React.useEffect(() => {
    // Sometimes when the user back navigates, the browser will remember the input values. These get filled in without triggering the input event. So we need to manually compute the group value here.
    computeGroupValue();
  }, [])

  const computeGroupValue = () => {
    const newGroupValue = inputRefs.current.map((input) => {
      if (input?.value === '') {
        return '_'
      }
      return input?.value
    }).join('')

    setGroupValue(newGroupValue)
  }

  const handleBeforeInput = (event: any, index: number) => {
    const inputCharacter: Character = event.data
    
    if (!isAlphabetic(inputCharacter)) {
      event.preventDefault();
    }
  }

  const handleInput = (event: any, index: number) => {
    const { nativeEvent } = event;
    const inputCharacter: Character = nativeEvent.data

    computeGroupValue();

    if (inputCharacter && isAlphabetic(inputCharacter)) {
      inputRefs.current[index + 1]?.focus();
    }
  }

  const handleKeyUp = (event: React.KeyboardEvent, index: number) => {
    // Delete
    if (event.code === "Backspace" || event.key === "Backspace" || event.code === "Delete" || event.key === "Delete") {
      inputRefs.current[index - 1]?.focus();
    }

    // Arrow left
    if (event.code === "ArrowLeft" || event.key === "ArrowLeft") {
      inputRefs.current[index - 1]?.focus();
    }

    // Arrow right
    if (event.code === "ArrowRight" || event.key === "ArrowRight") {
      inputRefs.current[index + 1]?.focus();
    }

    // Space
    if (event.code === "Space" || event.key === "Space") {
      inputRefs.current[index + 1]?.focus();
    }
  }



  return (
    <SCInputGroup>
      {value.map((char, index) => 
          <SCInputElem
            key={index}
            maxLength={1}
            type="text"
            ref={ref => inputRefs.current[index] = ref}
            onBeforeInput={e => handleBeforeInput(e, index)}
            onInput={e => handleInput(e, index)}
            onKeyUp={e => handleKeyUp(e, index)}
          />
      )}

      <input type="hidden" name="found" value={groupValue}></input>
    </SCInputGroup>
  )
}

export { SCInput }