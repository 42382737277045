import {
  createBrowserRouter
} from "react-router-dom";

import { SearchForm } from '@components/search/SearchForm';
import { Search } from '@components/views/SearchView';

const router = createBrowserRouter([
  {
    path: "/",
    element: <SearchForm />,
  },
  {
    path: "/search",
    element: <Search />,
  }
]);

export default router;